import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Tooltip, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { getLocalDateTimeString } from '../../utils/date';
const { Search } = Input;

export const getTableConfig = ({
  tableData,
  setTableData,
  search,
  onDelete,
  onSPSSDownload,
}) => {
  return [
    {
      title: 'Cím',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'title', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Megjegyzés',
      dataIndex: 'summary',
      key: 'summary',
      align: 'center',
    },
    {
      title: 'Típus',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Publikálás dátuma',
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      sorter: true,
      align: 'center',
      render: publishedAt => <span>{getLocalDateTimeString(publishedAt)}</span>,
    },
    {
      title: 'Műveletek',
      key: 'action',
      render: (text, { uuid, deleted }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Link to={`survey/${uuid}`}>
            <Tooltip title="Szerkesztés">
              <Button type="primary" icon={<FormOutlined />} size={'small'} />
            </Tooltip>
          </Link>
          <Tooltip title="SPSS letöltés">
            <Button
              onClick={() => onSPSSDownload({ uuid })}
              type="default"
              icon={<DownloadOutlined />}
              size={'small'}
            />
          </Tooltip>
          <Popconfirm
            title="Biztos a törlésben"
            cancelText="Mégsem"
            okText="Igen"
            onConfirm={() => onDelete({ uuid, tableData, setTableData })}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="danger" icon={<DeleteOutlined />} size={'small'} />
          </Popconfirm>
        </div>
      ),
    },
  ];
};
